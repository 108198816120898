<template>
  <div class="canvas-bg" :width="size.width" :height="size.height" :style="style"></div>
</template>

<script>
/* eslint-disable */
import * as THREE from 'three';
import { Vortex } from './animation_engines/vortex.js';
import { Morphing } from './animation_engines/morphing.js';

import Color from '@/mixins/editor/Color';
import Polar from '@/mixins/editor/Color';

export default {
    inject: ['currentPage'],
    name: 'Canvas-background',
    props: ['slides', 'dimensions', 'animation', 'media', 'fromTo', 'progress', 'realSize', 'scale', 'slideEars', 'boxed'],
    mixins: [Polar, Color],
    data: function() {
        return {
            images: [],
            preCanvases: [],
            canvases: [null, null],
            textures: [null, null]
        };
    },
    watch: {
        dimensions: function() {
            this.theResize();
        },
        fromTo: {
            handler: function(val) {
                this.canvases[0].sourceCanvas = this.preCanvases[val[0]];
                this.canvases[1].sourceCanvas = this.preCanvases[val[1]];
                this.preCanvases[val[0]].actual = false;
                this.preCanvases[val[1]].actual = false;
                this.$nextTick(function() {
                    this.engine.setProgress(this.progress);
                    this.tick();
                });
            }
        },
        progress: function() {
            this.$nextTick(function() {
                this.engine.setProgress(this.progress);
                this.tick();
            });
        }
    },
    mounted: function() {
        this.$nextTick(function() {
            return this.init();
        });
    },
    beforeDestroy: function() {
        var anim;
        if(!this.currentPage.isRenderer) {
            anim = this.animation.type;
            this[anim + 'Destroy']();
            this.currentPage.resize();
        }
    },
    computed: {
        size: function() {
            var height, width;
            if(this.boxed) {
                width = this.realSize.w;
                height = this.realSize.h;
            } else {
                width = parseInt(this.dimensions.width);
                height = parseInt(this.dimensions.height);
            }
            if(width === 0) {
                width = 320;
            }
            if(height === 0) {
                height = 200;
            }
            return { width, height };
        },
        selfScale: function() {
            var key, ratio, realRatio;
            ratio = this.size.width / this.size.height;
            realRatio = this.realSize.w / this.realSize.h;
            if(ratio > realRatio) {
                key = 'height';
            } else {
                key = 'width';
            }
            return this.size[key] / this.realSize[key[0]];
        },
        style: function() {
            if(this.boxed) {
                return {
                    width: this.realSize.w + 'px',
                    height: this.realSize.h + 'px',
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%) scale(${this.scale}`
                };
            } else {
                return {
                    width: this.size.width + 'px',
                    height: this.size.height + 'px'
                };
            }
        }
    },
    methods: {
        theResize: function() {
            var counter, interval;
            [...this.canvases, ...this.preCanvases].forEach((c) => {
                c.width = this.size.width;
                c.height = this.size.height;
                return {};
            });

            this.preCanvases.forEach((c, i) => {
                this.updatePreCanvas(i);
            });
            counter = 0;
            interval = setInterval(() => {
                counter++;
                if(counter > 40) {
                    clearInterval(interval);
                }
                // При выходе из полноэкранного режима по esc
                // engine к этому моменту может уже не существовать
                if(this.engine) {
                    this.engine.resize();
                }
            }, 50);
        },
        init: function() {
            var anim, urlPrefix;
            urlPrefix = this.currentPage.storageUrl;
            // Инициализируем фоны
            this.slides.forEach((s, i) => {
                var img, imgCanvas, media, mediaId, that, url;
                // Создаем объект для картинки
                url = '';
                mediaId = s.bg.styles['background-image'];
                if(mediaId !== '') {
                    media = this.media[mediaId];
                    if(media.temp) {
                        url = this.media[mediaId].url;
                    } else {
                        url = urlPrefix + this.media[mediaId].url;
                    }
                }
                img = new Image();
                img.crossOrigin = 'Anonymous';
                if(url) {
                    img.src = url;
                }
                that = this;
                img.onload = function() {
                    return that.updatePreCanvas(that.images.indexOf(this));
                };
                this.images[i] = img;
                // Создаем канвасы для фонов
                imgCanvas = this.createBlankCanvas();
                this.preCanvases[i] = imgCanvas;
                this.$nextTick(function() {
                    this.updatePreCanvas(i);
                });
            });
            this.canvases.forEach((c, i, a) => {
                var canvas, ctx, sourceCanvas;
                canvas = this.createBlankCanvas();
                ctx = canvas.getContext('2d');
                sourceCanvas = this.preCanvases[i];
                if(this.slides.length === 1) {
                    sourceCanvas = this.preCanvases[0];
                }
                ctx.drawImage(sourceCanvas, 0, 0);
                canvas.sourceCanvas = sourceCanvas;
                a[i] = canvas;
            });
            this.textures.forEach((t, i, a) => {
                var texture;
                texture = new THREE.CanvasTexture(this.canvases[i]);
                // Очень важная строка!!!
                // Отключение мипмаппинга!!!
                // Без неё электрон не может сделать ресайз текстуры
                // и не рендерит изображение
                texture.minFilter = THREE.LinearFilter;
                texture.needsUpdate = true;
                a[i] = texture;
            });
            anim = this.animation.type;
            this.$nextTick(function() {
                this[anim + 'Init']();
            });
        },
        createBlankCanvas: function() {
            var newCanvas;
            newCanvas = document.createElement('canvas');
            newCanvas.width = this.size.width;
            newCanvas.height = this.size.height;
            newCanvas.actual = true;
            return newCanvas;
        },
        updatePreCanvas: function(index) {
            var bgAspect, canAng, canvas, cdx, cdy, centerX, centerY, cssAng, ctx, cx, cy, distFromCenter, ds, grad, h,
                i, imageHeight, imageWidth, img, imgAspect, imgRatio, invScaleX, invScaleY, j, k, l, left, m, maxShift,
                moreCols, moreRows, offsetMultiH, offsetMultiW, opts, originX, originY, ratio, ref, ref1, ref2, ref3,
                sc, scale, scaleX, scaleY, slideHeight, slideRatio, slideWidth, t, top, w;
            canvas = this.preCanvases[index];
            ctx = canvas.getContext('2d');
            ctx.globalAlpha = 1;
            scaleX = 1;
            scaleY = 1;
            ctx.rect(0, 0, this.size.width, this.size.height);
            ctx.fillStyle = '#fff';
            ctx.fill();
            opts = this.slides[index].bg.styles;
            ctx.save();
            // Если сплошная заливка
            if(opts['color-mode'] === 'solid') {
                ctx.rect(0, 0, this.size.width, this.size.height);
                ctx.fillStyle = 'rgba(' + this.getValidColor(opts['background-color']) + ')';
                ctx.fill();
            }
            if(opts['color-mode'] === 'gradient') {
                if(opts.gradient.type === 'linear') {
                    w = this.size.width;
                    h = this.size.height;
                    cx = w / 2;
                    cy = h / 2;
                    cssAng = opts.gradient.angle;
                    canAng = cssAng - 180;
                    ds = this.gradCoords(cx, cy, canAng);
                    grad = ctx.createLinearGradient(...ds);
                    opts.gradient.points.forEach((p) => {
                        var c, pos;
                        pos = p.position / 100;
                        c = 'rgba(' + this.getValidColor(p.color) + ')';
                        return grad.addColorStop(pos, c);
                    });
                    ctx.fillStyle = grad;
                    ctx.fillRect(0, 0, w, h);
                }
                if(opts.gradient.type === 'radial') {
                    distFromCenter = opts.gradient.center.map(function(v) {
                        return Math.abs(50 - v);
                    });
                    offsetMultiH = 1 + 2 * (distFromCenter[0] / 100);
                    offsetMultiW = 1 + 2 * (distFromCenter[1] / 100);
                    ratio = (this.size.height * offsetMultiW) / (this.size.width * offsetMultiH);
                    if(ratio < 1) {
                        scaleY = ratio;
                    } else {
                        scaleX = 1 / ratio;
                    }
                    invScaleX = 1 / scaleX;
                    invScaleY = 1 / scaleY;
                    ctx.setTransform(scaleX, 0, 0, scaleY, 0, 0);
                    w = this.size.width;
                    h = this.size.height;
                    cx = w * opts.gradient.center[0] / 100;
                    cy = h * opts.gradient.center[1] / 100;
                    cdx = w * (0.5 + distFromCenter[0] / 100);
                    cdy = h * (0.5 + distFromCenter[1] / 100);
                    grad = ctx.createRadialGradient(cx / scaleX, cy / scaleY, 0, cx / scaleX, cy / scaleY, Math.sqrt(cdx * cdx * (invScaleX * invScaleX) + cdy * cdy * (invScaleY * invScaleY)));
                    opts.gradient.points.forEach((p) => {
                        var c, pos;
                        pos = p.position / 100;
                        c = 'rgba(' + this.getValidColor(p.color) + ')';
                        return grad.addColorStop(pos, c);
                    });
                    ctx.fillStyle = grad;
                    ctx.fillRect(0, 0, w / scaleX, h / scaleY);
                }
            }
            ctx.restore();
            if(this.images[index].naturalWidth > 0 && this.images[index].naturalHeight > 0) {
                img = this.images[index];
                ctx.globalAlpha = opts['image-opacity'];
                ctx.save();
                switch(opts['image-fill-mode']) {
                    case 'cover':
                        imgAspect = img.width / img.height;
                        bgAspect = this.size.width / this.size.height;
                        if(imgAspect > bgAspect) {
                            // Вписываем по высоте
                            scale = this.size.height / img.height;
                            h = img.height * scale;
                            w = img.width * scale;
                            l = -(w - this.size.width) / 2;
                            t = 0;
                        } else {
                            // Вписываем по ширине
                            scale = this.size.width / img.width;
                            h = img.height * scale;
                            w = img.width * scale;
                            l = 0;
                            t = -(h - this.size.height) / 2;
                        }
                        ctx.drawImage(img, l / scaleX, t / scaleY, w / scaleX, h / scaleY);
                        break;
                    case 'stretch':
                        ctx.drawImage(img, 0, 0, this.size.width / scaleX, this.size.height / scaleY);
                        break;
                    case 'tile':
                        sc = opts['image-scale'] * this.selfScale;
                        w = this.images[index].naturalWidth * sc;
                        h = this.images[index].naturalHeight * sc;
                        centerX = this.size.width / 2;
                        centerY = this.size.height / 2;
                        originX = centerX - w / 2;
                        originY = centerY - h / 2;
                        moreCols = originX / w;
                        moreRows = originY / h;
                        if(moreCols > 0) {
                            moreCols = Math.ceil(moreCols);
                        } else {
                            moreCols = 0;
                        }
                        if(moreRows > 0) {
                            moreRows = Math.ceil(moreRows);
                        } else {
                            moreRows = 0;
                        }
                        for(i = k = ref = -moreRows, ref1 = moreRows; k <= ref1; i = k += 1) {
                            for(j = m = ref2 = -moreCols, ref3 = moreCols; m <= ref3; j = m += 1) {
                                ctx.drawImage(img, (centerX - w / 2 + j * w) / scaleX, (centerY - h / 2 + i * h) / scaleY, w / scaleX, h / scaleY);
                            }
                        }
                        break;
                    case 'custom':
                        img = this.images[index];
                        ctx.globalAlpha = opts['image-opacity'];
                        imageWidth = opts['background-image-width'];
                        imageHeight = opts['background-image-height'];
                        imgRatio = imageWidth / imageHeight;
                        slideWidth = this.size.width;
                        slideHeight = this.size.height;
                        slideRatio = slideWidth / slideHeight;
                        if(imgRatio > slideRatio) { // фон шире, выступает вправо-влево
                            imageHeight = slideHeight;
                            imageWidth = imageHeight * imgRatio; // фон уже, выступает вверх-вниз
                        } else {
                            imageWidth = slideWidth;
                            imageHeight = imageWidth / imgRatio;
                        }
                        sc = opts['image-custom-scale'];
                        imageWidth *= sc;
                        imageHeight *= sc;
                        maxShift = {
                            x: ((imageWidth / slideWidth) - 1) / 2,
                            y: ((imageHeight / slideHeight) - 1) / 2
                        };
                        left = (0.5 + maxShift.x * opts['image-shift-x']) * slideWidth - 0.5 * imageWidth;
                        top = (0.5 + maxShift.y * opts['image-shift-y']) * slideHeight - 0.5 * imageHeight;
                        ctx.drawImage(img, left, top, imageWidth, imageHeight);
                }
                ctx.restore();
            }
            canvas.actual = false;
            this.tick();
        },
        tick: function() {
            var ref;
            this.canvases.forEach((c, i) => {
                var ctx;
                if(!c.sourceCanvas.actual) {
                    ctx = c.getContext('2d');
                    ctx.drawImage(c.sourceCanvas, 0, 0);
                    c.sourceCanvas.actual = true;
                    this.textures[i].needsUpdate = true;
                }
            });
            if((ref = this.engine) != null) {
                ref.tick();
            }
        },
        vortexInit: function() {
            this.vortexDestroy();
            this.engine = new Vortex({
                container: this.$el,
                width: this.size.width,
                height: this.size.height,
                textures: [this.textures[0], this.textures[1]]
            });
            this.engine.setProgress(0);
        },
        vortexDestroy: function() {
            var container, ref, ref1;
            container = this.$el;
            if(this.engine != null) {
                this.engine.destroy();
                if((ref = container.childNodes[0]) != null) {
                    ref.remove();
                }
                delete this.engine;
            } else {
                if((ref1 = container.childNodes[0]) != null) {
                    ref1.remove();
                }
            }
        },
        morphingInit: function() {
            this.morphingDestroy();
            this.engine = new Morphing({
                el: this.$el,
                textures: this.textures,
                dispImage: './images/displacements/1.png',
                direction: this.animation.direction
            });
            this.engine.setProgress(0);
        },
        morphingDestroy: function() {
            var container, ref, ref1;
            container = this.$el;
            if(this.engine != null) {
                this.engine.destroy();
                if((ref = container.childNodes[0]) != null) {
                    ref.remove();
                }
                delete this.engine;
            } else {
                if((ref1 = container.childNodes[0]) != null) {
                    ref1.remove();
                }
            }
        }
    }
};
</script>

<style lang="scss">
.canvas-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
