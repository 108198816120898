<template>
    <div class="animation-container" @mousedown.stop="" @mouseup.stop=""
         @click.stop.prevent.left="next()" @click.stop.prevent.right="prev()">
        <div class="presentation">
            <div class="bg-trimmer" :style="bgTrimmerStyle">
                <canvas-bg
                    ref="canvasBg"
                    :scale="scale"
                    :boxed="boxed"
                    :realSize="size"
                    :slides="slides"
                    v-if="webglAnimation"
                    :fromTo="webglFromTo"
                    :slideEars="slideEars"
                    :progress="webglProgress"
                    :media="vjsons.meta.media"
                    :animation="vjsons.meta.animation"
                    :dimensions="{width: vw, height: vh}"
                />
                <div class="backgrounds" v-if="!webglAnimation" :style="bgsDivStyles">
                    <slide_bg
                        :bg="bg"
                        :key="bg.id"
                        v-for="bg in bgs"
                        :slideWidth="size.w"
                        :slideHeight="size.h"
                        :diff="bgAndContentDifference.x"
                    />
                </div>
            </div>
            <div class="slides-trimmer">
                <div class="slides" :style="slidesDivStyles">
                    <template v-for="slide in slides">
                        <slide_object v-for="object in slide.objects" :obj="object" :key="object.id" :slideSize="size"
                                      :bgAndContentDifference="bgAndContentDifference"></slide_object>
                    </template>
                </div>
            </div>
            <div class="screen"
                 style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: transparent;">
                <div class="screen__fragment" v-for="fragment in screenFragmentsProgress"
                     :style="{transform: 'translateY(' + -fragment.p*100 + '%)', background: 'rgba(' + modifyScreenColor(fragment.p) + ')'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import _ from "lodash";
import $ from 'jquery';
import gsap3 from 'gsap3';

import AnimationSlide from "@/components/AnimationSlide";
import AnimationObject from "@/components/AnimationObject";
import CanvasBg from "@/components/CanvasBg/CanvasBg";

export default {
    name: 'Animation_controller',
    props: ['vjsons', 'resizeFlag', 'animationPreview', 'boxed'],
    components: {
        slide_bg: AnimationSlide,
        slide_object: AnimationObject,
        canvasBg: CanvasBg
    },
    data: function () {
        return {
            scale: 1,
            bgScale: 1,
            vh: 0,
            vw: 0,
            defaultAnimationState: {
                tx: 0,
                ty: 0,
                scale: 1,
                opacity: 1
            },
            slides: [],
            animations: [],
            previewFrames: null,
            frameIndex: 0,
            slideIndex: 0,
            easingsMap: {
                easeIn: "Power2.in",
                easeOut: "Power2.out",
                easeInOut: "Power2.inOut",
                none: "none"
            },
            immediate: false,
            screenOpacity: 0,
            screenProgress: -1,
            screenFragmentsProgress: [
                {
                    p: -1
                }
            ],
            screenColor: [0, 0, 0, 1],
            webglFromTo: [0, 0],
            webglProgress: 0,
            webglAnimationTypes: ['vortex', 'morphing'],
            timeline: null,
            gsaps: [],
            autoplayOn: false
        };
    },
    beforeMount: function () {
        var slides;
        slides = _.cloneDeep(this.vjsons.slides);
        slides.forEach((s, i) => {
            s.bg.params = _.clone(this.defaultAnimationState);
            s.objects.forEach((o, i) => {
                o.params = _.clone(this.defaultAnimationState);
            });
        });
        this.slides = slides;
        this.newFrame = {
            slides: {}
        };
        this.timeline = {
            keyFrame: null,
            frames: []
        };
        this.init();
    },
    beforeDestroy: function () {
        this.$emit('destroyed');
    },
    mounted: function () {
        this.$nextTick(function () {
            this.calcScale();
        });
    },
    watch: {
        resizeFlag: {
            handler: function () {
                this.calcScale();
            },
            immediate: true
        },
        gsaps: function (val) {
            if (val.length === 0) {
                this.tryNext();
            }
        },
        frameIndex: function (val) {
            this.$emit('frameindexchanged', val);
        },
        slideIndex: function (val) {
            this.$emit('slideindexchanged', val);
        }
    },
    computed: {
        bgs: function () {
            return this.slides.map(function (vjson) {
                return vjson.bg;
            });
        },
        size: function () {
            return this.vjsons.meta.size;
        },
        slideEars: function () {
            var scaledSlideHeight, scaledSlideWidth;
            this.resizeFlag;
            scaledSlideWidth = this.size.w * this.scale;
            scaledSlideHeight = this.size.h * this.scale;
            return {
                ver: (this.vh - scaledSlideHeight) / 2,
                hor: (this.vw - scaledSlideWidth) / 2
            };
        },
        slidesDivStyles: function () {
            return {
                width: this.size.w + 'px',
                height: this.size.h + 'px',
                transform: 'scale(' + this.scale + ')',
                top: this.slideEars.ver + 'px',
                left: this.slideEars.hor + 'px'
            };
        },
        bgsDivStyles: function () {
            if (this.boxed) {
                return Object.assign({}, this.slidesDivStyles, {
                    'transform-origin': 'top left',
                    overflow: 'hidden'
                });
            } else {
                return {};
            }
        },
        bgTrimmerStyle: function () {
            if (this.boxed) {
                return {
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                };
            } else {
                return {};
            }
        },
        bgAndContentDifference: function () {
            var h, w;
            w = this.bgRealSize.width;
            h = this.bgRealSize.height;
            return {
                x: w / this.size.w,
                y: h / this.size.h
            };
        },
        bgRealSize: function () {
            return {
                width: Math.round(this.bgScale * this.vw),
                height: Math.round(this.bgScale * this.vh)
            };
        },
        animationType: function () {
            return this.vjsons.meta.animation.type;
        },
        webglAnimation: function () {
            return this.webglAnimationTypes.includes(this.animationType);
        },
        atEnd: function () {
            return this.frameIndex === this.timeline.frames.length;
        },
        atStart: function () {
            return this.frameIndex === 1;
        },
        timelineIndexes: function () {
            var fi, frames, res, si;
            if (!this.timeline) {
                return [];
            } else {
                si = 1;
                fi = 1;
                res = [
                    {
                        slide: 1,
                        frame: 1
                    }
                ];
                frames = this.timeline.frames;
                frames.forEach(function (fr, fri) {
                    var nextFrame;
                    if (fri !== 0) {
                        if (fr.slideChange) {
                            si++;
                            fi = 1;
                        } else {
                            fi++;
                        }
                        res.push({
                            slide: si,
                            frame: fi
                        });
                    }
                    // Нужно удалить номер фрейма,
                    // если фрейм был один
                    nextFrame = frames[fri + 1];
                    if (fi === 1 && (!nextFrame || nextFrame.slideChange)) {
                        delete res[res.length - 1].frame;
                    }
                });
                return res;
            }
        }
    },
    methods: {
        calcScale: function () {
            if (this.$el != undefined) {
                const canvas = $(this.$el).find('.canvas');
                let scale = this.scale;
                const ph = this.size.h;
                const pw = this.size.w;
                const vh = this.$el.clientHeight;
                const vw = this.$el.clientWidth;
                if (pw / ph > vw / vh) {
                    scale = vw / pw;
                }
                if (pw / ph <= vw / vh) {
                    scale = vh / ph;
                }
                this.scale = scale;
                this.bgScale = 1 / scale;
                this.vh = vh;
                this.vw = vw;
            }
        },
        init: function () {
            this.buildTimeline();
            this.slides.forEach((s, si) => {
                var kf;
                kf = this.timeline.keyFrame;
                Object.keys(kf.slides[si].keys).forEach(function (sk) {
                    return s.bg.params[sk] = kf.slides[si].keys[sk];
                });
                s.objects.forEach(function (o, oi) {
                    Object.keys(kf.slides[si].objects[oi].keys).forEach(function (ok) {
                        return s.objects[oi].params[ok] = kf.slides[si].objects[oi].keys[ok];
                    });
                });
            });
            this.$emit('init');
            if (this.animationPreview) {
                this.previewFrames = this.findFrames(this.animationPreview);
                this.$nextTick(function () {
                    this.gotoFrame(this.previewFrames[0]);
                });
                setTimeout(() => {
                    this.tryNext();
                }, 2000);
            } else {
                this.next(true);
            }
        },
        buildTimeline: function () {
            var animation, app, c, defaultAnimationOpts, defaultAnimationState, diff, dir, duration, easing, keyFrame,
                objectKeys, screenColor, slideChangeParams, slideKeys;
            app = this;
            animation = this.vjsons.meta.animation;
            duration = animation.duration;
            easing = animation.easing;
            defaultAnimationState = this.defaultAnimationState;
            defaultAnimationOpts = [
                {
                    object: {
                        time: duration,
                        easing: easing,
                        delay: 0
                    },
                    slide: {
                        time: duration,
                        easing: easing,
                        delay: 0
                    }
                },
                {
                    object: {
                        time: duration,
                        easing: easing,
                        delay: 0
                    },
                    slide: {
                        time: duration,
                        easing: easing,
                        delay: 0
                    }
                }
            ];
            // Собираем начальное состояние
            keyFrame = {
                slides: {}
            };
            switch(animation.type) {
                case 'none':
                case 'fade':
                    slideKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    objectKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    break;
                case 'slide':
                    dir = 't' + animation.direction;
                    diff = animation.diff;
                    diff = 1 - diff;
                    slideKeys = {
                        prev: {},
                        next: {}
                    };
                    slideKeys.prev[dir] = -1;
                    slideKeys.next[dir] = 1;
                    objectKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    objectKeys.prev[dir] = -1 * diff;
                    objectKeys.next[dir] = 1 * diff;
                    break;
                case 'screen':
                    slideKeys = {
                        prev: {
                            scale: 1.1,
                            opacity: 0
                        },
                        next: {
                            scale: 1.1,
                            opacity: 0
                        }
                    };
                    objectKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    if (animation.color.own) {
                        screenColor = animation.color.own;
                    } else {
                        if (animation.color.palette) {
                            c = animation.color.palette;
                            screenColor = this.vjsons.meta.palette[c];
                        } else {
                            screenColor = [0, 0, 0, 1];
                        }
                    }
                    slideChangeParams = {
                        screen: {
                            color: screenColor,
                            time: duration,
                            fragments: animation.fragments
                        }
                    };
                    defaultAnimationOpts[0].object.time = 0;
                    defaultAnimationOpts[0].slide.time = 0;
                    defaultAnimationOpts[1].slide.time = 0;
                    defaultAnimationOpts[1].object.time = 0;
                    defaultAnimationOpts[0].object.delay = duration * 0.5;
                    defaultAnimationOpts[0].slide.delay = duration * 0.5;
                    defaultAnimationOpts[1].slide.delay = duration * 0.5;
                    defaultAnimationOpts[1].object.delay = duration * 0.5;
                    break;
                case 'vortex':
                case 'morphing':
                    slideKeys = {
                        prev: {},
                        next: {}
                    };
                    objectKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    slideChangeParams = {
                        webgl: {
                            type: animation.type,
                            time: duration,
                            easing: easing
                        }
                    };
                    defaultAnimationOpts[0].object.time = duration * 0.5;
                    defaultAnimationOpts[1].object.time = duration * 0.5;
                    defaultAnimationOpts[1].object.delay = duration * 0.5;
                    break;
                default:
                    slideKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
                    objectKeys = {
                        prev: {
                            opacity: 0
                        },
                        next: {
                            opacity: 0
                        }
                    };
            }
            // Собираем фреймы
            this.slides.forEach((s, si) => {
                var firstSlide, hasFragments, slideObjectsIds;
                if (si === 0) {
                    firstSlide = true;
                }
                if (s.animations.length > 0) {
                    hasFragments = true;
                }
                // Начальное состояние слайда
                keyFrame.slides[si] = {
                    keys: this.calcAnimationState(slideKeys['next']),
                    objects: {}
                };
                if (!firstSlide) {
                    this.newFrame.slides[si - 1] = {
                        keys: {
                            from: this.calcAnimationState(),
                            to: this.calcAnimationState(slideKeys['prev'])
                        },
                        opts: _.cloneDeep(defaultAnimationOpts[0].slide),
                        objects: {}
                    };
                    this.slides[si - 1].objects.forEach((o, oi) => {
                        return this.newFrame.slides[si - 1].objects[oi] = {
                            keys: {
                                from: this.calcAnimationState(),
                                to: this.calcAnimationState(objectKeys['prev'])
                            },
                            opts: _.cloneDeep(defaultAnimationOpts[0].object)
                        };
                    });
                    this.newFrame.slideChange = true;
                }
                // Сделать si слайд текущим
                this.newFrame.slides[si] = {
                    keys: {
                        from: this.calcAnimationState(slideKeys['next']),
                        to: this.calcAnimationState()
                    },
                    opts: _.cloneDeep(defaultAnimationOpts[1].slide),
                    objects: {}
                };
                if (firstSlide) {
                    this.newFrame.slides[si].opts.time = 0;
                    this.newFrame.slides[si].opts.delay = 0;
                } else {
                    if (slideChangeParams != null) {
                        if (slideChangeParams.screen) {
                            this.newFrame.screen = slideChangeParams.screen;
                        }
                        if (slideChangeParams.webgl) {
                            this.newFrame.webgl = slideChangeParams.webgl;
                        }
                    }
                }
                if (!hasFragments) {
                    this.slides[si].objects.forEach((o, oi) => {
                        this.newFrame.slides[si].objects[oi] = {
                            keys: {
                                from: this.calcAnimationState(objectKeys['next']),
                                to: this.calcAnimationState()
                            },
                            opts: _.cloneDeep(defaultAnimationOpts[1].object),
                            id: o.id
                        };
                        // Зафиксировать начальное состояние
                        return keyFrame.slides[si].objects[oi] = {
                            keys: this.calcAnimationState(objectKeys['next'])
                        };
                    });
                    this.pushFrame();
                } else {
                    // Иначе, рассчитать фрагменты
                    this.pushFrame();
                    slideObjectsIds = s.objects.map(function (o) {
                        return o.id;
                    });
                    // Собираем фреймы анимаций
                    s.animations.forEach((order, orderIndex) => {
                        this.newFrame.slides[si] = {
                            objects: {}
                        };
                        order.forEach((id) => {
                            var aa, aad, theObject, theObjectIndex;
                            theObjectIndex = slideObjectsIds.indexOf(id);
                            slideObjectsIds[theObjectIndex] = undefined;
                            theObject = s.objects[theObjectIndex];
                            animation = theObject.animation;
                            aa = animation.params['animation-autoplay'];
                            aad = animation.params['animation-autoplay-delay'];
                            this.newFrame.slides[si].objects[theObjectIndex] = {
                                keys: {
                                    from: this.calcAnimationState(animation.next),
                                    to: this.calcAnimationState()
                                },
                                opts: {
                                    time: animation.params['animation-duration'],
                                    easing: animation.params['animation-easing'],
                                    autoplay: aa ? true : false,
                                    delay: aa ? aad : 0
                                },
                                id: id
                            };
                            // Зафиксировать начальное состояние
                            keyFrame.slides[si].objects[theObjectIndex] = {
                                keys: this.calcAnimationState(animation.next)
                            };
                        });
                        this.pushFrame();
                    });
                    // Показываем остальные объекты
                    this.newFrame.slides[si] = {
                        objects: {}
                    };
                    slideObjectsIds.forEach((soi, soiIndex) => {
                        if (soi !== undefined) {
                            this.newFrame.slides[si].objects[soiIndex] = {
                                keys: {
                                    from: this.calcAnimationState({
                                        opacity: 0
                                    }),
                                    to: this.calcAnimationState()
                                },
                                opts: {
                                    time: 0,
                                    easing: 'none',
                                    delay: 0
                                }
                            };
                            // Зафиксировать начальное состояние
                            return keyFrame.slides[si].objects[soiIndex] = {
                                keys: this.calcAnimationState(this.calcAnimationState({
                                    opacity: 0
                                }))
                            };
                        }
                    });
                    if (Object.keys(this.newFrame.slides[si].objects).length > 0) {
                        this.pushFrame();
                    }
                }
            });
            this.timeline.keyFrame = keyFrame;
        },
        calcAnimationState: function (overrides) {
            var das, res;
            res = {};
            das = this.defaultAnimationState;
            Object.keys(das).forEach(function (k) {
                if ((overrides != null) && (overrides[k] != null)) {
                    res[k] = overrides[k];
                } else {
                    res[k] = das[k];
                }
            });
            return res;
        },
        pushFrame: function () {
            this.timeline.frames.push(_.cloneDeep(this.newFrame));
            this.newFrame = {
                slides: {}
            };
        },
        next: function (immediate) {
            var frame;
            if (this.gsaps.length > 0) {
                this.finishAnimation();
            }
            if (this.frameIndex === this.timeline.frames.length) {
                return;
            }
            if (this.timeline.frames.length === 1 && this.frameIndex > 0) {
                return;
            }
            frame = this.timeline.frames[this.frameIndex];
            if (frame != null) {
                if (immediate) {
                    this.immediate = true;
                    this.autoplayOn = false;
                } else {
                    this.autoplayOn = true;
                }
                Object.keys(frame.slides).forEach((sk) => {
                    var frameSlide, targetSlide;
                    targetSlide = this.$data.slides[sk];
                    frameSlide = frame.slides[sk];
                    if (frameSlide.opts) {
                        this.runGsap(targetSlide.bg.params, frameSlide.keys.from, Object.assign(frameSlide.keys.to, {
                            delay: frameSlide.opts.delay / 1000,
                            ease: this.easingsMap[frameSlide.opts.easing],
                            duration: frameSlide.opts.time / 1000
                        }));
                    }
                    Object.keys(frameSlide.objects).forEach((ok) => {
                        var frameObject, targetObject;
                        targetObject = this.$data.slides[sk].objects[ok];
                        frameObject = frame.slides[sk].objects[ok];
                        this.runGsap(targetObject.params, frameObject.keys.from, Object.assign(frameObject.keys.to, {
                            delay: frameObject.opts.delay / 1000,
                            ease: this.easingsMap[frameObject.opts.easing],
                            duration: frameObject.opts.time / 1000
                        }));
                    });
                });
                if (frame.slideChange) {
                    this.slideIndex = Math.max(...Object.keys(frame.slides));
                }
                if (frame.screen) {
                    this.runScreen(frame.screen, this.immediate);
                }
                if (frame.webgl) {
                    this.runWebgl(Object.keys(frame.slides), frame.webgl, false, this.immediate);
                }
                if (this.immediate) {
                    this.immediate = false;
                }
            }
            this.frameIndex++;
        },
        tryNext: function () {
            var allObjectsHaveAutoplay, frame, targetSlide, targetSlideIndex;
            if (!this.autoplayOn && !this.animationPreview) {
                return;
            }
            frame = this.timeline.frames[this.frameIndex];
            if (frame != null) {
                // В одном фрейме могут быть 2 слайда
                // Нужен только с большим индексом (следующий)
                targetSlideIndex = Math.max(...Object.keys(frame.slides).map(function (k) {
                    return parseInt(k);
                }));
                targetSlide = frame.slides[targetSlideIndex];
                if (targetSlide.objects) {
                    if (Object.keys(targetSlide.objects).length > 0) {
                        allObjectsHaveAutoplay = _.every(targetSlide.objects, function (o) {
                            return o.opts.autoplay;
                        });
                    }
                }
            }
            if (this.animationPreview) {
                if (this.frameIndex !== this.previewFrames[1]) {
                    this.next();
                } else {
                    setTimeout(() => {
                        this.$emit('finishedpreview');
                    }, 1000);
                }
            } else {
                if (allObjectsHaveAutoplay) {
                    this.next();
                }
            }
        },
        prev: function () {
            var frame;
            if (this.gsaps.length > 0) {
                this.finishAnimation();
            }
            if (this.frameIndex === 1) {
                console.log('no more fragments');
                return;
            }
            this.autoplayOn = false;
            frame = this.timeline.frames[this.frameIndex - 1];
            if (frame != null) {
                Object.keys(frame.slides).forEach((sk) => {
                    var frameSlide, targetSlide;
                    targetSlide = this.$data.slides[sk];
                    frameSlide = frame.slides[sk];
                    if (frameSlide.opts) {
                        this.runGsap(targetSlide.bg.params, frameSlide.keys.to, Object.assign(frameSlide.keys.from, {
                            duration: 0
                        }));
                    }
                    Object.keys(frameSlide.objects).forEach((ok) => {
                        var frameObject, targetObject;
                        targetObject = this.$data.slides[sk].objects[ok];
                        frameObject = frame.slides[sk].objects[ok];
                        this.runGsap(targetObject.params, frameObject.keys.to, frameObject.keys.from, Object.assign(frameObject.keys.from, {
                            duration: 0
                        }));
                    });
                });
                if (frame.slideChange) {
                    this.slideIndex = Math.min(...Object.keys(frame.slides));
                }
                if (frame.webgl) {
                    this.runWebgl(Object.keys(frame.slides), frame.webgl, true);
                }
            }
            this.frameIndex--;
        },
        gotoSlide: function (slideIndex) {
            var frames, targetFrameIndex, timelineSlideIndex;
            targetFrameIndex = -1;
            timelineSlideIndex = -1;
            frames = this.timeline.frames;
            frames.forEach(function (f, fi) {
                if (slideIndex === timelineSlideIndex) {
                    return;
                }
                targetFrameIndex = fi;
                if (f.slideChange) {
                    timelineSlideIndex++;
                    targetFrameIndex--;
                }
            });
            targetFrameIndex++;
            // Для последнего фрейма
            if (slideIndex !== timelineSlideIndex) {
                targetFrameIndex++;
            }
            this.gotoFrame(targetFrameIndex);
        },
        gotoLastSlide: function () {
            this.gotoSlide(this.slides.length - 1);
        },
        gotoFrame: function (targetFrameIndex) {
            var method, prevFrameIndex;
            if (this.timeline.frames.length === 1 && targetFrameIndex !== 1) {
                return;
            }
            if (targetFrameIndex > this.timeline.frames.length) {
                targetFrameIndex = this.timeline.frames.length;
            }
            if (targetFrameIndex < 1) {
                targetFrameIndex = 1;
            }
            if (targetFrameIndex > this.frameIndex) {
                method = this.next;
            }
            if (targetFrameIndex < this.frameIndex) {
                method = this.prev;
            }
            if (method != null) {
                prevFrameIndex = undefined;
                while (!(targetFrameIndex === this.frameIndex || prevFrameIndex === this.frameIndex)) {
                    prevFrameIndex = this.frameIndex;
                    method(true);
                }
            }
        },
        findFrames: function (params) {
            var endFrame, findObjects, objectsFrames, startFrame;
            if (params.objects.length > 0) {
                findObjects = true;
                objectsFrames = [];
            } else {
                findObjects = false;
                // У первого слайда нет предыдущей анимации,
                // поэтому начинаем поиск со второго
                if (params.slide === 0) {
                    params.slide++;
                }
            }
            startFrame = undefined;
            endFrame = undefined;
            this.timeline.frames.forEach(function (f, fi) {
                var theSlide;
                if (findObjects) {
                    theSlide = f.slides[params.slide];
                    if (theSlide != null) {
                        Object.keys(theSlide.objects).forEach(function (o) {
                            if (params.objects.includes(theSlide.objects[o].id)) {
                                objectsFrames.push(fi);
                            }
                        });
                    }
                } else {
                    if (f.slides[params.slide] != null) {
                        if (startFrame == null) {
                            startFrame = fi;
                        }
                        if (endFrame == null) {
                            endFrame = fi;
                        }
                    }
                }
            });
            if (startFrame == null) {
                startFrame = Math.min(...objectsFrames);
            }
            if (endFrame == null) {
                endFrame = Math.max(...objectsFrames);
            }
            endFrame++;
            return [startFrame, endFrame];
        },
        finishAnimation: function () {
            var gsaps;
            gsaps = _.clone(this.gsaps);
            if (this.gsaps.length !== 0) {
                this.gsaps = [];
                gsaps.forEach(function (g) {
                    delete g.vars.onComplete;
                    g.progress(1);
                });
                this.screenFragmentsProgress.forEach(function (f) {
                    return f.p = -1;
                });
            }
        },
        runScreen: function (params, immediate) {
            var allFragmentsDelays, fragmentDelay, fragmentTime, fragments, halfTime, i, l, m, ref, ref1, the;
            if (immediate) {
                return;
            }
            the = this;
            fragments = params.fragments;
            halfTime = params.time / 2;
            this.screenColor = params.color;
            if (this.screenFragmentsProgress.length !== params.fragments) {
                this.screenFragmentsProgress = [];
                for (i = l = 0, ref = params.fragments; l < ref; i = l += 1) {
                    this.screenFragmentsProgress.push({
                        p: -1
                    });
                }
            }
            fragmentDelay = (halfTime / params.fragments) * 0.3;
            allFragmentsDelays = fragmentDelay * (params.fragments - 1);
            fragmentTime = halfTime - allFragmentsDelays;
            for (i = m = 0, ref1 = params.fragments; m < ref1; i = m += 1) {
                the.runGsap(the.$data.screenFragmentsProgress[i], {
                    p: -1
                }, {
                    p: 0,
                    duration: fragmentTime / 1000,
                    delay: (fragmentDelay * i) / 1000,
                    onCompleteParams: [i],
                    onComplete: function (index) {
                        var gi, j, n, ref2;
                        if (index === fragments - 1) {
                            for (j = n = 0, ref2 = params.fragments; n < ref2; j = n += 1) {
                                the.runGsap(the.$data.screenFragmentsProgress[j], {
                                    p: 0
                                }, {
                                    p: 1,
                                    duration: fragmentTime / 1000,
                                    delay: (fragmentDelay * j) / 1000
                                });
                            }
                        }
                        gi = the.gsaps.indexOf(this);
                        the.gsaps.splice(gi, 1);
                    }
                });
            }
        },
        runWebgl: function (slideIndexes, params, reverse, immediate) {
            var duration, from, to;
            from = Math.min(...slideIndexes);
            to = Math.max(...slideIndexes);
            duration = params.time;
            if (immediate) {
                duration = 0;
            }
            if (reverse) {
                [from, to] = [to, from];
                duration = 0;
            }
            this.webglFromTo = [from, to];
            this.runGsap(this.$data, {
                webglProgress: 0
            }, {
                webglProgress: 1,
                duration: duration / 1000
            });
        },
        modifyScreenColor: function (progress) {
            return this.screenColor.map(function (c, i) {
                if (i < 3) {
                    return c * (1 - Math.abs(progress / 5));
                } else {
                    return c;
                }
            });
        },
        runGsap: function (obj, optsFrom, optsTo) {
            var g, myOptsFrom, myOptsTo, the;
            myOptsTo = _.cloneDeep(optsTo);
            myOptsFrom = _.cloneDeep(optsFrom);
            if (this.immediate) {
                myOptsTo.duration = 0;
            }
            // Грязный хак, gsap не учитывает delay
            // при нулевой длительности анимации
            if (myOptsTo.duration === 0 && !this.immediate) {
                myOptsTo.duration = 0.016;
            }
            the = this;
            if (myOptsTo.onComplete == null) {
                myOptsTo.onComplete = function () {
                    var index;
                    index = the.gsaps.indexOf(this);
                    the.gsaps.splice(index, 1);
                };
            }
            g = gsap3.fromTo(obj, myOptsFrom, myOptsTo);
            if (the.gsaps == null) {
                the.gsaps = [];
            }
            the.gsaps.push(g);
        }
    }
};
</script>

<style lang="scss">
.animation-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1d1f20;

    .curve__selected-border {
        display: none;
    }

    .shape__selected-border {
        display: none;
    }

    .table__selected-border {
        display: none;
    }

    .text-placeholder {
        display: none;
    }

    .presentation {
        background-color: #000;
    }

    .slides {
        position: absolute;
        transform-origin: top left;
        top: 0;
        left: 0;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .screen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;

        &__fragment {
            height: 100%;
            width: 100%;
            background-color: #fff;
        }
    }
}
</style>
