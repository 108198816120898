/* eslint-disable */

import _ from "lodash";

export default {
    name: 'Color_mixin',
    methods: {
        getValidColor (color) {
            if (color instanceof Array) {
                return [...color, 1];
            }
            if (color.own) {
                return color.own;
            }
            if (color.palette) {
                const palette = this.$store != null
                    ? this.$store.state.presentation.theme.palette
                    : this.$root.meta.palette;
                
                return [...palette[color.palette], 1];
            }
        },
        calculatePalette: function (palette, row) {
            var newPalette;
            newPalette = this.generatePalette({
                acc1: palette.acc1l50,
                acc2: palette.acc2l50,
                mut1: palette.mut1l50,
                mut2: palette.mut2l50
            }, palette.isDark);
            Object.keys(newPalette).forEach(function (k) {
                if (!k.includes(row)) {
                    newPalette[k] = palette[k];
                }
            });
            return newPalette;
        },
        generatePalette: function (colors, inverted) {
            var i, j, palette, v;
            palette = {};
            _.forEach(colors, (val, key) => {
                var hsl, shades;
                hsl = this.rgb2hsl(val);
                shades = ['l10', 'l30', 'l50', 'l70', 'l90'];
                if (inverted) {
                    shades.reverse();
                }
                palette[key + shades[0]] = this.hsl2rgb([hsl[0], hsl[1], hsl[2] / 5]);
                palette[key + shades[1]] = this.hsl2rgb([hsl[0], hsl[1], hsl[2] / 5 * 3]);
                palette[key + shades[2]] = _.clone(val);
                palette[key + shades[3]] = this.hsl2rgb([hsl[0], hsl[1], (100 - hsl[2]) / 5 + hsl[2]]);
                palette[key + shades[4]] = this.hsl2rgb([hsl[0], hsl[1], (100 - hsl[2]) / 5 * 3 + hsl[2]]);
            });
            for (i = j = 0; j <= 100; i = j += 25) {
                v = i;
                if (inverted) {
                    v = 100 - i;
                }
                palette['gsl' + i] = this.hsl2rgb([0, 0, v]);
            }
            palette.isDark = inverted;
            return palette;
        },
        rgb2hsl: function (arr) {
            let h = undefined;
            let s = undefined;
            let l = undefined;
            let d = undefined;
            let r = arr[0] / 255;
            let g = arr[1] / 255;
            let b = arr[2] / 255;
            let max = Math.max(r, g, b);
            let min = Math.min(r, g, b);
            l = (max + min) / 2;
            
            if (max === min) {
                h = s = 0;
            } else {
                d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch(max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                }
                h /= 6;
            }
            h = Math.floor(h * 360);
            s = Math.floor(s * 100);
            l = Math.floor(l * 100);
            return [h, s, l];
        },
        hsl2rgb: function (arr) {
            let r = undefined;
            let g = undefined;
            let b = undefined;
            let m = undefined;
            let c = undefined;
            let x = undefined;
            let h = arr[0];
            let s = arr[1];
            let l = arr[2];
            if (!isFinite(h)) {
                h = 0;
            }
            if (!isFinite(s)) {
                s = 0;
            }
            if (!isFinite(l)) {
                l = 0;
            }
            h /= 60;
            if (h < 0) {
                h = 6 - (-h % 6);
            }
            h %= 6;
            s = Math.max(0, Math.min(1, s / 100));
            l = Math.max(0, Math.min(1, l / 100));
            c = (1 - Math.abs(2 * l - 1)) * s;
            x = c * (1 - Math.abs(h % 2 - 1));
            if (h < 1) {
                r = c;
                g = x;
                b = 0;
            } else if (h < 2) {
                r = x;
                g = c;
                b = 0;
            } else if (h < 3) {
                r = 0;
                g = c;
                b = x;
            } else if (h < 4) {
                r = 0;
                g = x;
                b = c;
            } else if (h < 5) {
                r = x;
                g = 0;
                b = c;
            } else {
                r = c;
                g = 0;
                b = x;
            }
            m = l - (c / 2);
            r = Math.round((r + m) * 255);
            g = Math.round((g + m) * 255);
            b = Math.round((b + m) * 255);
            return [r, g, b];
        }
    }
};
