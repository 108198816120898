/* eslint-disable */
export default {
  data() {
    return {
      acReady: false
    };
  },
  computed: {
    atTimelineStart() {
      if (this.acReady) {
        return this.$refs.animation_controller.atStart;
      } else {
        return true;
      }
    },
    atTimelineEnd() {
      if (this.acReady) {
        return this.$refs.animation_controller.atEnd;
      } else {
        return true;
      }
    },
    frameIndex() {
      if (this.acReady) {
        return this.$refs.animation_controller.frameIndex;
      } else {
        return 0;
      }
    },
    totalFrames() {
      if (this.acReady) {
        return this.$refs.animation_controller.timeline.frames.length;
      } else {
        return 0;
      }
    },
    timelineIndex() {
      if (this.acReady) {
        return this.$refs.animation_controller.timelineIndexes[this.frameIndex - 1];
      } else {
        return {
          slide: 0
        };
      }
    },
    currentFrameText() {
      const ti = this.timelineIndex;
      if (ti != null) {
        if (ti.frame) {
          return `${ti.slide}.${ti.frame}`;
        } else {
          return `${ti.slide}`;
        }
      } else {
        return '';
      }
    }
  }
};
