/* eslint-disable */
import * as THREE from 'three'

function Morphing(opts) {
  const vertex = require('./morphing.vs')

  const fragment = require('./morphing.fs')

  const el = opts.el

  this.morphingAngleModifier = opts.direction == 'x' ? 1 : 3

  const intensity1 = 1
  const intensity2 = 1
  let commonAngle = (Math.PI / 4) * this.morphingAngleModifier
  let angle1 = commonAngle
  let angle2 = -commonAngle * 3

  let texture1 = null
  let texture2 = null
  let mat = null

  const dispImage = opts.dispImage

  const scene = new THREE.Scene()
  const camera = new THREE.OrthographicCamera(
    el.offsetWidth / -2,
    el.offsetWidth / 2,
    el.offsetHeight / 2,
    el.offsetHeight / -2,
    1,
    1000
  )

  camera.position.z = 1

  const renderer = new THREE.WebGLRenderer({ antialias: false })

  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setClearColor(0xffffff, 0.0)
  renderer.setSize(el.offsetWidth, el.offsetHeight)
  el.appendChild(renderer.domElement)

  const render = function () {
    renderer.render(scene, camera)
  }

  const loader = new THREE.TextureLoader()
  loader.crossOrigin = ''

  texture1 = opts.textures[0]
  texture2 = opts.textures[1]

  const disp = loader.load(dispImage, render)
  disp.wrapS = disp.wrapT = THREE.RepeatWrapping

  mat = new THREE.ShaderMaterial({
    uniforms: {
      intensity1: { type: 'f', value: intensity1 },
      intensity2: { type: 'f', value: intensity2 },
      dispFactor: { type: 'f', value: 0.0 },
      angle1: { type: 'f', value: angle1 },
      angle2: { type: 'f', value: angle2 },
      texture1: { type: 't', value: texture1 },
      texture2: { type: 't', value: texture2 },
      disp: { type: 't', value: disp }
    },

    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity: 1.0
  })

  const geometry = new THREE.PlaneBufferGeometry(el.offsetWidth, el.offsetHeight, 1)
  const mesh = new THREE.Mesh(geometry, mat)
  scene.add(mesh)

  const animate = function () {
    render()
  }

  this.el = opts.el

  this.tick = animate
  this.scene = scene
  this.renderer = renderer

  this.setProgress = function (progress) {
    mat.uniforms.dispFactor.value = progress
  }

  this.changeUniform = function (uniform, value) {
    if (uniform == 'direction') {
      if (value == 'x') {
        this.morphingAngleModifier = 1
      }
      if (value == 'y') {
        this.morphingAngleModifier = 3
      }
      commonAngle = (Math.PI / 4) * this.morphingAngleModifier
      angle1 = commonAngle
      angle2 = -commonAngle * 3
      mat.uniforms.angle1.value = angle1
      mat.uniforms.angle2.value = angle2
    }
  }

  this.set0 = function () {
    mat.uniforms.dispFactor.value = 0.0
  }

  this.destroy = function () {
    this.scene.children[0].geometry.dispose();
    this.scene.children[0].material.dispose();
    this.scene.children[0].material.uniforms.disp.value.dispose();
    this.scene.children[0].material.uniforms.texture1.value.dispose();
    this.scene.children[0].material.uniforms.texture2.value.dispose();
    this.scene.dispose();
    delete this.textures;
  }

  this.resize = function () {
    const rendererSize = renderer.getSize(new THREE.Vector2(0, 0))
    if (rendererSize.x !== el.offsetWidth || rendererSize.y !== el.offsetHeight) {
      renderer.setSize(el.offsetWidth, el.offsetHeight)
    }
  }

}

export {Morphing as Morphing}